exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-alert-unsubscribe-tsx": () => import("./../../../src/templates/alertUnsubscribe.tsx" /* webpackChunkName: "component---src-templates-alert-unsubscribe-tsx" */),
  "component---src-templates-article-list-page-tsx": () => import("./../../../src/templates/articleListPage.tsx" /* webpackChunkName: "component---src-templates-article-list-page-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/articlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-auth-confirm-account-page-tsx": () => import("./../../../src/templates/auth/confirmAccountPage.tsx" /* webpackChunkName: "component---src-templates-auth-confirm-account-page-tsx" */),
  "component---src-templates-auth-create-account-page-tsx": () => import("./../../../src/templates/auth/createAccountPage.tsx" /* webpackChunkName: "component---src-templates-auth-create-account-page-tsx" */),
  "component---src-templates-auth-login-page-tsx": () => import("./../../../src/templates/auth/loginPage.tsx" /* webpackChunkName: "component---src-templates-auth-login-page-tsx" */),
  "component---src-templates-auth-password-request-page-tsx": () => import("./../../../src/templates/auth/passwordRequestPage.tsx" /* webpackChunkName: "component---src-templates-auth-password-request-page-tsx" */),
  "component---src-templates-auth-password-reset-page-tsx": () => import("./../../../src/templates/auth/passwordResetPage.tsx" /* webpackChunkName: "component---src-templates-auth-password-reset-page-tsx" */),
  "component---src-templates-business-registry-tsx": () => import("./../../../src/templates/businessRegistry.tsx" /* webpackChunkName: "component---src-templates-business-registry-tsx" */),
  "component---src-templates-business-tsx": () => import("./../../../src/templates/business.tsx" /* webpackChunkName: "component---src-templates-business-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contactUs.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-dashboard-dashboard-business-create-tsx": () => import("./../../../src/templates/dashboard/dashboardBusinessCreate.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-business-create-tsx" */),
  "component---src-templates-dashboard-dashboard-business-edit-tsx": () => import("./../../../src/templates/dashboard/dashboardBusinessEdit.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-business-edit-tsx" */),
  "component---src-templates-dashboard-dashboard-business-translate-tsx": () => import("./../../../src/templates/dashboard/dashboardBusinessTranslate.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-business-translate-tsx" */),
  "component---src-templates-dashboard-dashboard-home-tsx": () => import("./../../../src/templates/dashboard/dashboardHome.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-home-tsx" */),
  "component---src-templates-dashboard-dashboard-members-tsx": () => import("./../../../src/templates/dashboard/dashboardMembers.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-members-tsx" */),
  "component---src-templates-dashboard-dashboard-opportunities-tsx": () => import("./../../../src/templates/dashboard/dashboardOpportunities.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-opportunities-tsx" */),
  "component---src-templates-dashboard-dashboard-opportunity-create-tsx": () => import("./../../../src/templates/dashboard/dashboardOpportunityCreate.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-opportunity-create-tsx" */),
  "component---src-templates-dashboard-dashboard-opportunity-edit-tsx": () => import("./../../../src/templates/dashboard/dashboardOpportunityEdit.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-opportunity-edit-tsx" */),
  "component---src-templates-dashboard-dashboard-opportunity-translate-tsx": () => import("./../../../src/templates/dashboard/dashboardOpportunityTranslate.tsx" /* webpackChunkName: "component---src-templates-dashboard-dashboard-opportunity-translate-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-newsletter-tsx": () => import("./../../../src/templates/newsletter.tsx" /* webpackChunkName: "component---src-templates-newsletter-tsx" */),
  "component---src-templates-opportunities-tsx": () => import("./../../../src/templates/opportunities.tsx" /* webpackChunkName: "component---src-templates-opportunities-tsx" */),
  "component---src-templates-opportunity-tsx": () => import("./../../../src/templates/opportunity.tsx" /* webpackChunkName: "component---src-templates-opportunity-tsx" */),
  "component---src-templates-terms-conditions-tsx": () => import("./../../../src/templates/termsConditions.tsx" /* webpackChunkName: "component---src-templates-terms-conditions-tsx" */)
}

