/**
 * Write-only the password as cookie
 */
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import React, { useState } from 'react';

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(false);

  const sitePwd =
    typeof process.env.GATSBY_SITE_PWD === 'undefined'
      ? ''
      : process.env.GATSBY_SITE_PWD;

  const onSubmit = (event) => {
    event.preventDefault();
    const valid = sitePwd === password;
    setFormError(!valid);
    if (valid) {
      setSessionPassword(password);
      window.location.reload(); // eslint-disable-line
    }
  };

  return (
    <form className="pwd-form" onSubmit={onSubmit}>
      <h1>Welcome</h1>
      <h4>Enter Password</h4>
      <input
        name="password"
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />

      <span className="filled">
        <button type="submit" className="btn btn-primary">
          Enter
        </button>
      </span>
      {formError && (
        <div className="form-error-container">Sorry, wrong the password</div>
      )}
    </form>
  );
};

export default PasswordProtect;
